import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButtonPS = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
  color: var(--secondary-text);
  width: 128px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, settotalSupply] = useState(0);
  const [maxMintAmount, setmaxMintAmount] = useState(0);
  const [addressMintedBalance, setaddressMintedBalance] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [tokenId] = useState(0);
  const [mintPass] = useState(333);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);

  const handleAnswerOptionClick = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checktotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(0)
      .call()
      .then((receipt) => {
        settotalSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkmaxMintAmount = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(0)
      .call()
      .then((receipt) => {
        setmaxMintAmount(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkaddressMintedBalance = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(0, blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMintedBalance(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ミント中です。しばらくお待ちください...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(tokenId, mintAmount, mintPass)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("失敗したので再度試してみてください！");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `ミントが成功しました！OpenSeaで確認してみてください！`
        );
        setClaimingNft(false);
        checkaddressMintedBalance();
        dispatch(fetchData(blockchain.account));
      });
  };

  const Image = () => {
    return (
      <img src="/config/images/toma.jpeg" alt="covered" style={{maxWidth : "240px"}} />
    );
  };
  

  const questions = [
  {
    id: 1,
    question: "○○○に入る言葉を選びなさい。カバードピープルとは数百年後も繁栄する経済圏/○○○を伴ったコミュニティブランドを創るプロジェクである。",
    options: ["①太陽拳", "②高倉健", "③生活圏", "④沖縄県"],
    answer: "③生活圏"
  },
  {
    id: 2,
    question: "カバードピープルの前看板である名称は次のうちどれでしょう？",
    options: ["①全日本かぶりもの協会", "②KABURE", "③クリプト研究室", "④ヒオキン仮想通貨ラボ（HCL）"],
    answer: "③クリプト研究室"
  },
  {
    id: 3,
    question: "カバードピープルのジェネシスコレクションは全部で何作品でしょうか？",
    options: ["①203", "②222", "③200", "④198"],
    answer: "③200"
  },
  {
    id: 4,
    question: "カバードの階級制度において初の降格者は次のうち誰でしょう？",
    options: ["①きゃべつ", "②サンチュ", "③れたす", "④はくさい"],
    answer: "③れたす"
  },
  {
    id: 5,
    question: "ヒオキン氏が「おそらく即売します」と豪語したのにも関わらず売れ残ったため、しゃーなしでkitさんが購入した作品は？",
    options: ["①上", "②スナック菓子", "③鉄仮面", "④寿司"],
    answer: "③鉄仮面"
  },
  {
    id: 6,
    question: "ファウンダーのヒオキン氏が、コミュニティ運営に置いて最も影響を受けた人物は？",
    options: ["①ホリエモン", "②鴨頭嘉人", "③西野亮廣", "④イケダハヤト"],
    answer: "③西野亮廣"
  },
  {
    id: 7,
    question: "非公式オフィシャルファウンダー、薩摩川内市のふるさと納税などを手がけた人物は次のうちどれ？",
    options: ["①やまだやまだドットコム", "②やまだやまだドットジェイピー", "③まつだまつだドットコム", "④まつだまつだドットジェイピー"],
    answer: "③まつだまつだドットコム"
  },
  {
    id: 8,
    question: "次の階級制度のうち、最も偉くて硬い組み合わせを選びなさい。",
    options: ["①領主と大理石", "②庄屋とコンクリート", "③名主と大理石", "④名主とフランソワ"],
    answer: "③名主と大理石"
  },
  {
    id: 9,
    question: "公式姉妹コレクション、Coverd Recordsのファーストシングルの曲名は？",
    options: ["①ICHIGO", "②TABASUKO", "③TOMATO", "④PIZA"],
    answer: "③TOMATO"
  },
  {
    id: 10,
    question: "大喜利でいっぽんの回答者に送られるNFT。次のうち存在しないものを選びなさい。",
    options: ["①こけし", "②マトリョーシカ", "③薬草", "④地蔵"],
    answer: "③薬草"
  },
];

  useEffect(() => {
    getData();
    getConfig();
    checktotalSupply();
    checkmaxMintAmount();
    checkaddressMintedBalance();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checktotalSupply();
    checkmaxMintAmount();
    checkaddressMintedBalance();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.TextDescription
        style={{ fontSize: 20, padding: "15px 20px 10px 12px", textAlign: "left", color: "#fff",backgroundColor: "rgba(22,94,131)"}}
      >
        <StyledLink style={{color: "#fff", margin: "12px 20px 2px 5px", fontWeight: "bold"}} href={"https://covered-kentei.pages.dev"}>
          カバード検定準二級試験
        </StyledLink>        
      </s.TextDescription>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 15, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >

        <ResponsiveWrapper flex={1} style={{ padding: 20 }} test>
          
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              padding: 10,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            


      <div className='app'>
      {showScore ? (
        <>
        {score >= 10 ? (
        <>
        <div className='score-section'>
            <s.SpacerSmall />
            <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display",　fontWeight: "bold"}}
                >【合格】
            </s.TextTitle>
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display",　fontWeight: "bold"}}
                  >10問正解
            </s.TextDescription>
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display",　fontWeight: "bold"}}
                  >合格おめでとうございます！
            </s.TextDescription>
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >認定書NFT(SBT)をmint出来ます
            </s.TextDescription>
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >↓
            </s.TextDescription>
            {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >
                  Thank you!!
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  →{CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButtonPS
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButtonPS>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    {/* セールここから */}
                    {tokenId == 0 ? (//確認
                        <>
                        {addressMintedBalance > maxMintAmount - 1 ? (
                          <>
                          <s.SpacerMedium />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>

                          <StyledButtonPS
                            disabled={1}//claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            style={{fontFamily: "'Italiana'", fontFamily: "DM+Serif+Display" }}
                          >
                            {"取得済み" }
                          </StyledButtonPS>
                          </s.Container>

                          </>
                        ) : (
                          <>
                          <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButtonPS
                                disabled={claimingNft ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                                style={{}}
                              >
                              {claimingNft ? "MINT NOW" : "MINT"}
                              </StyledButtonPS>
                            </s.Container>
                            {addressMintedBalance > 0 ? (
                                <>
                                </>                              
                            ):(
                            <></>
                            )}
                          </>
                        )}
                        </>
                      ) : (
                        <>
                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.TextDescription
                          style={{
                            color: "var(--accent-text)", fontFamily: "DM+Serif+Display"
                          }}
                        >
                        {"Coming Soon..."}
                        </s.TextDescription>
                        </s.Container>
                        </>
                      )}
                      {/* ここまで */}

                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
                    
                    <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >Free mint!
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >※gasはかかります
                </s.TextDescription>
        </div>
        </>
          ) : (
            <div className='score-section'>
              <s.SpacerSmall />
              <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display",　fontWeight: "bold"}}
                >【不合格】
            </s.TextTitle>
              <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display", margin: "5px 10px 10px 5px"}}
                >あなたの正解数は {score} 問 でした
            </s.TextDescription>
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display", margin: "5px 10px 10px 5px"}}
                >何度でも挑戦できます
            </s.TextDescription>
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display", margin: "5px 10px 10px 5px"}}
                >↓
            </s.TextDescription>
            <s.TextDescription
              style={{color: "#fff", margin: "20px 10px 10px 10px", fontWeight: "bold"}}
              >
              <StyledLink style={{color: "#fff", margin: "1px"}} href={"https://covered-kentei.pages.dev"}>
                挑戦する!!ﾜｧﾜｧ
              </StyledLink>
            </s.TextDescription>
            </div>
          )}
        </>
      ) : (
        <>
          <div className='question-section'>
            <div className='question-count'>
            <s.TextDescription
              style={{color: "#fff", margin: "20px 10px 20px 5px", fontWeight: "bold"}}
              >全問正解すると認定書NFT(Polygonチェーン/SBT)がmint出来ます！(Metamaskブラウザをご利用ください)
            </s.TextDescription>
            <s.TextDescription
              style={{color: "#fff", margin: "5px 10px 2px 5px", fontWeight: "bold"}}
              >【<span>問題 {currentQuestion + 1}</span>/{questions.length}】
              <div>
              {questions[currentQuestion].id === 9 ? (
                <Image />
              ) : (
              <div></div>
              )}
              </div>
            </s.TextDescription>
            </div>
            <s.TextDescription
              style={{color: "#fff", margin: "5px 10px 10px 5px", fontWeight: "bold"}}
              ><div className='question-text'>{questions[currentQuestion].question}</div>
            </s.TextDescription>
          </div>
          <div className='answer-section' style={{ textAlign: 'center' }}>
            {questions[currentQuestion].options.map((answerOption) => (
              <button onClick={() => handleAnswerOptionClick(answerOption === questions[currentQuestion].answer)}>{answerOption}</button>
            ))}
          </div>
        </>
      )}
    </div>
                <s.SpacerSmall />
            <s.SpacerMedium />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}
export default App;